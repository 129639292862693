import React from 'react'
import PropTypes from 'prop-types'
import { Container, Row, Col } from 'reactstrap'
import styled from 'styled-components'

import Breadcrumbs from '../Breadcrumbs'
import scssVars from '../../scss-variables'
import { useMediaContext } from '~providers/media'

const SemiFluidContainerWrapper = styled.div`
  position: relative;
  overflow: hidden;

  @media only screen and (min-width: ${scssVars.desktopMin}) {
    min-height: 300px;
  }
`

const SideStrip = styled.div`
  position: relative;
  margin-top: -3.5rem;
  display: inline-block;
  background-color: #EBE9E4;
  &:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: 100%;
    background-color: #EBE9E4;
  }
  h1 {
    letter-spacing: 1.4px;
    font-size: 21px;
    line-height: 1.25;
    font-weight: 300;
    color: ${scssVars.darkBrown};
  }
  @media only screen and (min-width: ${scssVars.desktopMin}) {
    margin-top: unset;
    margin-left: -1rem;
    width: 110%;
    z-index: 1;
    h1 {
      font-size: 36px;
    }
  }
`

const HeroImage = styled.div`
  position: absolute;
  height: 100%;
  max-height: 85vh;
  width: 50%;
  right: 0;
  top: 0;
  height: 100%;
  background: url(${(p) => p.img}) center / cover;
`

const Content = styled.div`
  h2 {
    font-size: 18px;
    line-height: 1.25;
    letter-spacing: -0.0125em;
    font-weight: 600;
    color: ${scssVars.darkBrown};
  }
  h3 {
    font-size: 18px;
    font-weight: 600;
  }
  ul {
    padding-left: 20px !important;
  }
  p {
    font-size: 16px;
    line-height: 1.5;
    font-weight: 400;
    color: ${scssVars.darkBrown};
    a {
      font-family: inherit !important;
    }
  }
  li {
    font-size: 16px;
    font-weight: 400;
    color: ${scssVars.darkBrown};
  }
  hr {
    border-color: ${scssVars.darkBrown};
    margin: 25px 0;
  }
  @media only screen and (min-width: ${scssVars.desktopMin}) {
    h2 {
      font-size: 14px;
    }
    h3 {
      font-size: 22px;
    }
    li {
      line-height: 1.5;
    }
  }
`

const HeroSection = ({ title, breadcrumb, children, rightColumnChildren, mobileImg, desktopImg, fluid }) => {
  const { isDesktop } = useMediaContext()
  return (
    <>
      {isDesktop() ? (
        <div className="d-none d-lg-block">
          <SemiFluidContainerWrapper>
            <Container>
              <Row>
                <Col lg={{ size: 6 }}>
                  <Breadcrumbs title={title} breadcrumb={breadcrumb} />
                  <SideStrip className="pl-3 py-4">
                    <h1 className="m-0">{title}</h1>
                  </SideStrip>
                  <Content className="pt-4 pb-5 pr-5">
                    {children}
                  </Content>
                </Col>
                {!fluid && (
                  <Col className="p-0">
                    <img src={desktopImg} className="w-100" alt={title} />
                    {rightColumnChildren}
                  </Col>
                )}
              </Row>
            </Container>
            {fluid && (<HeroImage img={desktopImg} />)}
          </SemiFluidContainerWrapper>
        </div>
      ) : (
        <div className="d-lg-none">
          <div className="container-fluid px-0">
            <img className="w-100" src={mobileImg} alt={title} />
            <SideStrip classsName="p-3 pr-4">
              <h1 className="m-0">{title}</h1>
            </SideStrip>
            <Breadcrumbs title={title} breadcrumb={breadcrumb} />
          </div>
          <Content className="pt-3 container-fluid">
            {children}
          </Content>
        </div>
      )}
    </>
  )
}

HeroSection.propTypes = {
  fluid: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]).isRequired,
  rightColumnChildren: PropTypes.any,
  mobileImg: PropTypes.string.isRequired,
  desktopImg: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  breadcrumb: PropTypes.shape({
    title: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired
  })
}

HeroSection.defaultProps = {
  fluid: true
}

export default HeroSection
