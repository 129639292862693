import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'gatsby'

import scssVars from '../scss-variables'

const BreadcrumbList = styled.ul`
  color: ${scssVars.darkBrown};
  display: none;
  list-style: none;
  overflow: hidden;
  font-size: 16px;
  padding: 0;

  @media only screen and (max-width: 1280px) {
    margin-left: 50px;
    margin-top: 6px;
  }
  @media only screen and (min-width: ${scssVars.desktopMin}) {
    display: block;
  }

  li {
    display: inline;
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
    letter-spacing: 0.025em;
    &:after {
      content: ' / ';
    }
    &:last-child:after {
      content: '';
    }
  }
`

const Breadbox = styled.div`
  padding: 20px 0 10px 0;
  ${BreadcrumbList} {
    margin-left: 0;
    li {
      font-family: 'Montserrat', san-serif;
    }
    a {
      color: ${scssVars.darkBrown};
      text-decoration: none;
      &:hover, &:focus {
        text-decoration: underline;
      }
    }
  }
`

const Breadcrumbs = ({ className, title, breadcrumb }) => (
  <div className={className}>
    <span className="d-lg-none">
      <div className="p-3">
        {breadcrumb ? (
          <Link className="text-clr-sambuca" to={breadcrumb.path}>
            &laquo; {breadcrumb.title}
          </Link>
        ) : (
          <Link className="text-clr-sambuca" to='/'>&#60; Home</Link>
        )}
      </div>
    </span>
    <span className="d-none d-lg-block">
      <Breadbox>
        <BreadcrumbList>
          <li>
            <Link to="/">Home</Link>
          </li>
          {breadcrumb && (
            <li>
              <Link to={breadcrumb.path}>{breadcrumb.title}</Link>
            </li>
          )}
          <li>{title}</li>
        </BreadcrumbList>
      </Breadbox>
    </span>
  </div>
)

Breadcrumbs.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  breadcrumb: PropTypes.shape({
    title: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired
  })
}

export default Breadcrumbs
