import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

const SEO = ({ description, lang, meta, image, title, pathname, children }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            description
            title
            siteUrl
          }
        }
      }
    `
  )

  const canonical = pathname ? `${site.siteMetadata.siteUrl}${pathname}` : null

  return (
    <Helmet
      htmlAttributes={{lang}}
      title={title}
      titleTemplate="LEATHER SPA - %s"
      defaultTitle="LEATHER SPA - The Art of Leather Care"
      link={canonical ? [{ rel: 'canonical', href: canonical }] : []}
      meta={[{
        name: 'description',
        content: description
      }, {
        itemprop: 'name',
        content: title
      }, {
        itemprop: 'image',
        content: 'https://leatherspa.imgix.net/Ready-To-Start_Banner-image@2x.png?w=150&h=150&fm=jpg'
      }, {
        property: 'og:title',
        content: title
      }, {
        property: 'og:site_name',
        content: 'LEATHER SPA'
      }, {
        property: 'og:description',
        content: description
      }, {
        property: 'og:type',
        content: 'website'
      }, {
        property: 'og:url',
        content: ''
      }, {
        property: 'og:image',
        content: 'https://leatherspa.imgix.net/Ready-To-Start_Banner-image@2x.png?fm=jpg'
      }, {
        property: 'og:image:width',
        content: 1200
      }, {
        property: 'og:image:height',
        content: 628
      }, {
        name: 'twitter:card',
        content: 'summary_large_image'
      }, {
        name: 'twitter:site',
        content: '@leatherspa'
      }, {
        name: 'twitter:creator',
        content: '@leatherspa'
      }, {
        name: 'twitter:title',
        content: title
      }, {
        name: 'twitter:description',
        content: description
      }, {
        name: 'fb:admins',
        content: '150815345016334'
      }]
      .concat(meta)}
    >
      <script id="ze-snippet" type="text/javascript" src="//static.zdassets.com/ekr/snippet.js?key=6a0d47c1-8936-4cdf-9f04-6028f859da93"></script>
      {children}
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  description: 'The ultimate experience for discerning clients seeking luxury shoe repair and leather care.'
}

SEO.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  children: PropTypes.element,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired
  }),
  pathname: PropTypes.string
}

export default SEO
