import React from 'react'
import { Link } from 'gatsby'
import { Container, Row, Col } from 'reactstrap'
import styled from 'styled-components'

import Layout from '~components/Layout'
import Main from '~components/layouts/Main'
import scssVars from '~components/scss-variables'
import SEO from '~components/seo'
import HeroSection from '~components/layouts/marketing/HeroSection'

const SectionHeader = styled.div`
  display: flex;
  flex-direction: column;
  h2 {
    font-weight: 200;
    color: ${scssVars.darkBrown};
    text-align: center;
    display: block;
    font-size: 44px;
    line-height: 1.25;
  }
  span {
    border: 1px solid ${scssVars.brown};
    height: 1px;
    margin: 0 auto 40px;
    width: 25%;
    display: block;
    margin: 25px auto;
  }
  @media only screen and (min-width: ${scssVars.desktopMin}) {
    flex-direction: row;
  }
`

const List = styled.ul`
  font-size: 16px;
  line-height: 1.5;
`

const ButtonWrapper = styled.div`
  @media only screen and (min-width: ${scssVars.desktopMin}) {
    display: flex;
    justify-content: center;
    align-items: center;
    a {
      width: 382px;
    }
  }
`

const storeFrontImg = 'https://leatherspa.imgix.net/IMG_9693.2.jpg?fit=crop&w=2000&h=1333'
const storeSideImg = 'https://leatherspa.imgix.net/IMG_9857.2.jpg?fit=crop&w=2000&h=1333'

const LicensedStoresPage = () => {
  return (
    <Layout>
      <SEO
        title="Licensed Stores"
        pathname="/licensed-stores"
        description="A brand experience that is unmistakably Leather Spa"
      />
      <Main>
        <HeroSection
          title="Licensed Stores"
          mobileImg={'https://leatherspa.imgix.net/IMG_0077.1.jpg?fit=crop&w=992&h=683'}
          desktopImg={'https://leatherspa.imgix.net/IMG_0077.1.jpg?fit=crop&w=1360&h=936'}
        >
          <h2>A brand experience that is unmistakably Leather Spa.</h2>
          <p>
            Leather Spa Licensed Stores allow licensees to offer customers more
            than just a great shoe and handbag care and repair service
            experience. From custom store design to robust ongoing support, our
            Licensed Stores allow you to immerse every customer in the full
            luxury service experience they cherish and seek out for their luxury
            goods.
          </p>
        </HeroSection>
        <Container>
          <SectionHeader className="mt-lg-5 mb-lg-3">
            <h2>Offer the Leather Spa Experience</h2>
            <span></span>
          </SectionHeader>
          <p className="text-center text-lg-left mb-4">Delight customers with the original store design, popular seasonal promotions, and a personal white glove service enjoyed by thousands of loyal customers every week.</p>
          <Row className="mb-lg-5">
            <Col xs={12} lg={6}>
              <h4 className="text-center text-lg-left">Advantages</h4>
              <List>
                <li>Create a community-gathering place for Leather Spa core customers that are looking for the best luxury accessories repair and care service</li>
                <li>Offer the superior repair experience that made Leather Spa famous and consumers love and crave</li>
                <li>Offer high-quality leather care products that create perfect pairings with care services and can increase ticket averages</li>
                <li>Take advantage of continuous product and operational innovation</li>
              </List>
            </Col>
            <Col xs={12} lg={6}>
              <h4 className="text-center text-lg-left">Included</h4>
              <List>
                <li>Unrivaled, customizable store design</li>
                <li>Leather Spa menu, equipment, training and support</li>
                <li>Seasonal promotions</li>
                <li>Proprietary technology, equipment and fixture package</li>
                <li>Exclusive Leather Spa suppliers program</li>
                <li>Comprehensive merchandise assortment</li>
                <li>Ongoing expertise, consulting and support including onsite visits</li>
              </List>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6}>
              <img
                src={storeSideImg}
                className="w-100 mb-5 mb-lg-0"
                srcSet={`${storeSideImg} 345w, ${storeSideImg} 580w`}
                sizes={`(max-width: ${scssVars.desktopMin}) 345px, 580px`}
                alt="Licensed Store Front"
              />
            </Col>
            <Col sm={12} md={6}>
              <img
                src={storeFrontImg}
                className="w-100"
                srcSet={`${storeFrontImg} 345w, ${storeFrontImg} 580w`}
                sizes={`(max-width: ${scssVars.desktopMin}) 345px, 580px`}
                alt="Licensed Store Side View"
              />
            </Col>
          </Row>
          <ButtonWrapper className="my-5">
            <Link to="/contact" role="button" className="btn btn-primary my-lg-3">
              Contact Us
            </Link>
          </ButtonWrapper>
        </Container>
      </Main>
    </Layout>
  )
}

export default LicensedStoresPage
